<template>
  <div :class="{ 'svg-icon': !removeIconClass }" v-html="iconContent"/>
</template>

<script setup>
import { icons } from '~/assets/data/icons';

const props = defineProps({
  icon: {
    type: String,
    default: null,
  },
  width: {
    type: String,
    default: null,
  },
  height: {
    type: String,
    default: null,
  },
  removeIconClass: {
    type: Boolean,
    default: false,
  },
});

const iconContent = ref('');
const width = ref(`${props.width ?? 26}px`);
const height = ref(`${props.height ?? 26}px`);

const iconCache = new Map();

watch(() => props.icon, async(newIcon) => {
  if (!newIcon) {
    iconContent.value = '';
    return;
  }

  try {
    const iconPath = Object.keys(icons).find((path) => {
      const fileName = path.split('/').pop()?.replace('.svg', '');
      return fileName === newIcon || fileName === `ico-${newIcon}`;
    });

    if (!iconPath) {
      console.warn(`Icon not found: ${newIcon}`);
      return;
    }

    if (iconCache.has(iconPath)) {
      iconContent.value = iconCache.get(iconPath);
      return;
    }

    const iconModule = await icons[iconPath]();
    if (typeof iconModule === 'string') {
      iconContent.value = iconModule;
    } else if (iconModule.default) {
      iconContent.value = iconModule.default;
    } else {
      console.error(`Invalid icon format for: ${newIcon}`);
      return;
    }

    iconCache.set(iconPath, iconContent.value);
  } catch (error) {
    console.error(`Failed to load icon: ${newIcon}`, error);
  }
}, { immediate: true, });
</script>

<style lang="scss" scoped>
.svg-icon {
  display: inline-flex;
  width: v-bind(width);
  height: v-bind(height);

  > :deep(svg) {
    margin: auto;
    flex: 1 1 auto;
  }
}
</style>
